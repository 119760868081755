import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  flex-wrap: nowrap;
  align-items: center;
`

const Stack = ({children,...style})=><Wrapper style={style?{...style}:undefined}>{children}</Wrapper>

export default Stack;

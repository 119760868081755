import axios from 'axios';

const axiosInstance = ()=>{}

// axiosInstance.interceptors.response.use((response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'));
const isProd = process.env.NODE_ENV === "production";

export const mock = ()=>{}
export const serverCall = axios.create({
  baseURL: isProd?'/api':'/api'
});

export const fileManager = axios.create({
  baseURL: isProd? 'https://filestream.expolightcontrols.net' : 'https://filestream.expolightcontrols.net'
})
export default axiosInstance;

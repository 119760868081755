export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const PROGRAM_TYPES = {
  COMMAND: "COMMAND",
  LINK: "LINK",
  WITH_PARAMS: "WITH_PARAMS",
  SLIDER: "SLIDER",
  SWITCH: "SWITCH",
  DYNAMIC: "DYNAMIC",
  VIDEO:"VIDEO",
};

export const SCREEN_TYPES = {
  REGULAR: "REGULAR",
  DIRECT_CONTROL: "DIRECT_CONTROL",
  FILE_MANAGER: "FILE_MANAGER",
};


export const BUTTON_SIZE = {
  MD:'MD',
  LG:'LG',
}

export const noBackground = [PROGRAM_TYPES.SWITCH];
export const noSize = [PROGRAM_TYPES.SWITCH, PROGRAM_TYPES.SLIDER];

import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ProjectLocationPicker } from './ProjectLocationPicker';
import { SocketContext } from '../../../contexts/SocketContext';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';

const ProjectEditForm = () =>{
  const { projectID } = useParams();
  const navigate = useNavigate();
  const connection = useContext(SocketContext);
  const [project, setProject] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const getProject = useCallback( () => {
    connection.socket.emit("projects:getProjectDetails",{projectUID:projectID},({status,message,data})=>{
      status === "success"? setProject(data): enqueueSnackbar(message,{variant:status});
    })
  }, [isMountedRef]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  if(!project) return <></>
  return(
    <Formik
      initialValues={{
        projectUID:project.uid,
        name: project.name ||'as',
        description:project.description|| '',
        location:{
          lng:project.location.lng,
          lat:project.location.lat
        },
        address: project.address || '',
        timeZone: project.timeZone || '',
        time:project.time || '',
        ip:project.ip || '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          name: Yup.string()
            .min(2),
          description: Yup.string()
            .max(255),
          location: Yup.object(),
          address: Yup.string()
            .min(2),
          timeZone: Yup.string()
            .required(),
          time: Yup.string(),
          ip: Yup.string()
            .min(8)
            .max(15)
        })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        setSubmitting(true);

        connection.socket.emit("projects:editProject", { ...values }, ({
          status,
          message
        }) => {
          if (status === 'success') {
            setStatus({ success: true });
            setSubmitting(false);
            navigate('/products');
          } else {
            setErrors({ submit: message });
            setStatus({ success: false });
            setSubmitting(false);
          }
          enqueueSnackbar(message, {
            variant: status
          });
        });

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setTouched,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Название проекта"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      label="Адрес"
                      name="address"
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(touched.timeZone && errors.timeZone)}
                      fullWidth
                      helperText={touched.timeZone && errors.timeZone}
                      label="Временная зона"
                      disabled
                      name="timeZone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.timeZone}
                      variant="outlined"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(touched.time && errors.time)}
                      fullWidth
                      helperText={touched.time && errors.time}
                      label="Время"
                      disabled
                      name="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.time}
                      variant="outlined"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      error={Boolean(touched.ip && errors.ip)}
                      fullWidth
                      helperText={touched.ip && errors.ip}
                      label="Белый IP проекта"
                      name="ip"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ip}
                      variant="outlined"
                    />
                  </Box>
                  <Typography
                    color="textSecondary"
                    sx={{
                      mb: 2,
                      mt: 3
                    }}
                    variant="subtitle2"
                  >
                    Description
                  </Typography>
                  <TextField
                    placeholder="Краткое описание"
                    multiline
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    fullWidth
                    rows={9}
                  />
                  {(touched.description && errors.description) && (
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>
                        {errors.description}
                      </FormHelperText>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={12}
            >
              <Card>
                <CardHeader title="Локализация"/>
                <CardContent>
                  <ProjectLocationPicker setTouched={setTouched} handleChangeForm={setFieldValue} location={values.location}/>
                </CardContent>
              </Card>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 3
                }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Редактировать
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default ProjectEditForm;

import lightLogo from "../media/white_logo.png";
import darkLogo from "../media/black_logo.png";
import lightMark from "../media/white_mark.png";
import darkMark from "../media/black_mark.png";

import { useTheme } from '@emotion/react';

const Logo = ({fullLogo,...rest}) => {

  const theme = useTheme();

  if(theme.palette.mode==="dark"){
    return fullLogo  ?
      <img style={{ width: '100%',...rest }} src={lightLogo} alt={'Expolight Cloud Control System'} />:
      <img style={{ maxWidth: "48px",...rest }} src={lightMark} alt={'Expolight Cloud Control System'} />;
  }
  if(theme.palette.mode === "light"){
    return fullLogo  ?
      <img style={{ width: '100%',...rest }} src={darkLogo} alt={'Expolight Cloud Control System'} />:
      <img style={{ maxWidth: "48px",...rest }} src={darkMark} alt={'Expolight Cloud Control System'} />;
  }
};

export default Logo;

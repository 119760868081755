import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import { AssignmentInd, ImportantDevices, People, Router } from '@material-ui/icons';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
import UserIcon from '../../icons/User';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { getAccessLevel } from '../../utils/getAccessLevel';

const sections = [
  {
    title: 'Администратор',
    items: [
      {
        title: 'Учётная запись',
        path: '/account',
        accessLevel:[4,5,6],
        icon: <UserIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Управление',
    items: [
      {
        title: 'Устройства',
        path: '/customers',
        icon: <Router fontSize="small" />,
        accessLevel:[4,5,6],
        children: [
          {
            title: 'Список',
            path: '/customers',
          }
        ]
      },
      {
        title: 'Проекты',
        path: '/products',
        icon: <ImportantDevices fontSize="small" />,
        accessLevel:[4,5,6],
        children: [
          {
            title: 'Список',
            path: '/products',
          },
          {
            title: 'Новый проект',
            path: '/products/create',
          }
        ]
      },
      {
        title: 'Пользователи',
        icon: <AssignmentInd fontSize="small" />,
        path: '/orders',
        accessLevel:[5,6],
        children: [
          {
            title: 'Список',
            path: '/users'
          }
        ]
      },
      {
        title: 'Админы',
        path: '/invoices',
        icon: <People fontSize="small" />,
        accessLevel:[6],
        children: [
          {
            title: 'Список',
            path: '/invoices'
          }
        ]
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                fullLogo
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Уровень доступа:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {getAccessLevel(user.accessLevel)}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              accessLevel = {user.accessLevel}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useDialog } from "src/hooks/useDialog";

export const ConfirmDialog = ({ actionMessage, onSubmit, children }) => {
  const { isOpen, close, open } = useDialog();
  return (
    <>
      <Dialog open={isOpen} onClose={close} fullWidth maxWidth={"sm"}>
        <DialogContent>
          {actionMessage}
          <Typography>{` не обратимо! Продолжить операцию?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} color={"error"} onClick={onSubmit}>
            Удалить
          </Button>
          <Button onClick={close}>Отмена</Button>
        </DialogActions>
      </Dialog>
      {children(open)}
    </>
  );
};

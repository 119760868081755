export const getAccessLevel = (lvl) => {
  const accessLevel = {
    1: 'Интерфейс',
    2: 'Пользователь',
    3: 'Администратор объекта',
    4: 'Контент менеджер',
    5: 'Наладчик',
    6: 'Администратор'
  };
  return accessLevel[lvl];
};


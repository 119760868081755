import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios, {fileManager, serverCall} from '../lib/axios';

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  isDeviceChecked: false,
  message: 'Bерификация устройства...',
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    serverCall.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    fileManager.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete serverCall.defaults.headers.common.Authorization;
    delete fileManager.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      user
    } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state) => ({
    ...state,
    isAuthenticated: true
  }),
  DEVISE_CHECK: (state, action) => {
    const {
      user,
      isDeviceChecked,
      message
    } = action.payload;
    return {
      ...state,
      isDeviceChecked,
      message,
      user: {
        ...user,
        accessLevel:+user.accessLevel
      }
    };
  },
  BLOCK: (state, action) => ({
    ...initialState,
    message: action.payload.message
  }),
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isDeviceChecked: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  signin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const signin = async (login, password) => {
    const response = await serverCall.post('/admin/auth', {
      login,
      password
    });
    const {
      accessToken,
      user
    } = response.data;
    dispatch({
      type: 'DEVISE_CHECK',
      payload: {
        isDeviceChecked: true,
        user
      }
    });
    setSession(accessToken);

    dispatch({
      type: 'LOGIN'
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/authentication/register', {
      email,
      name,
      password
    });
    const {
      accessToken,
      user
    } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        signin,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

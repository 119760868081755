import { useCallback, useState } from "react";

export const useDialog = ()=>{
    const [isOpen,setOpen] = useState(false);
    const close = useCallback(()=>{
        setOpen(false)
    },[setOpen])

    const open = useCallback(()=>{
        setOpen(true)
    },[setOpen]);

    return{
        isOpen,
        open,
        close
    }
}

import LocationPicker from 'react-location-picker';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';

const ProjectLocationPicker = ({
  handleChangeForm,
  location = {
    lat:48.45227494574429,
    lng:35.03651737783073
  },
  setTouched = ()=>{}
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const startLocation = useRef(location)
  async function handleChangeLocation({
    address,
    position
  }) {


    try{
      const res = await fetch(`https://api.timezonedb.com/v2.1/get-time-zone?key=4LF61L22RGM4&format=json&by=position&lat=${position.lat}&lng=${position.lng}`)
      const {
        zoneName,
        formatted
      } = await res.json();
      handleChangeForm('time', formatted.slice(0, formatted.length - 3),true);
      handleChangeForm('timeZone', zoneName);
      handleChangeForm('location.lat', position.lat);
      handleChangeForm('location.lng', position.lng);
      handleChangeForm('address', address);
    }catch (e) {
      enqueueSnackbar(e.message,{variand:"warning"})
    }

  }

  return (

    <LocationPicker
      containerElement={<div style={{ height: '100%' }}/>}
      mapElement={<div style={{ height: '400px' }}/>}
      defaultPosition={{
        ...startLocation.current
      }}
      onChange={handleChangeLocation}
      radius={-1}
    />

  );
};

ProjectLocationPicker.propTypes = {
  handleChangeForm: PropTypes.func
};

export { ProjectLocationPicker };

import useSettings from '../../hooks/useSettings';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
import ChevronRightIcon from '../../icons/ChevronRight';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import { ProjectEditForm } from '../../components/dashboard/product';


const ProductEdit = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>ExpolightCCS | Редактировать проекта</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Редактировать проект
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Управление
                </Typography>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/products"
                  variant="subtitle2"
                >
                  Список проектов
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Редактировать проект
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<ArrowLeftIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                  to="/products"
                  variant="outlined"
                >
                  Назад
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            < ProjectEditForm />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProductEdit;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, CircularProgress, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import Logo from '../../Logo';
import useAuth from '../../../hooks/useAuth';

export function DeviceVerify() {
  const { message } = useAuth();
  return (
    <>
      <Helmet>
        <title>Expolight CCS | Проверка устройства</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Проверка устройства
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <Typography
                  align="center"
                >
                  {message}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {message === 'В доступе отказано! Свяжитесь с вашим администратором.' ? (
                  <ErrorIcon
                    color="error"
                    fontSize="large"
                  />
                ) : <CircularProgress />}
              </Box>
              <Divider sx={{ my: 3 }} />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
}

export default DeviceVerify;
